import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill"
// import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Contact from "../components/Contact"
import style from "../components/Footer/Footer.module.css"

const ContactInfo = () =>
    <div className="uk-width-2-5@m uk-flex-first@m">
        <h2 className="h3__large uk-text-primary">Solera Advisors</h2>
        <p className="uk-text-grape-lightest">Enabling decisions through transparency.</p>
        <p className="uk-text-bold uk-text-grape-lightest">
            Chicago | Toronto | London | Kiel
        </p>
        <div className="uk-margin">
            <a href="https://twitter.com/" className="uk-icon-button uk-margin-small-right" target="_blank" data-uk-icon="twitter" />
            <a href="https://www.facebook.com//" className="uk-icon-button uk-margin-small-right" target="_blank" data-uk-icon="facebook" />
            <a href="https://www.linkedin.com/company/" className="uk-icon-button uk-margin-small-right" target="_blank" data-uk-icon="linkedin" />
        </div>
    </div>

function ContactPage({ data, location }) {

    return (
        <Layout location={location}>
            <SEO title={`Contact us`} />
            <section className="uk-section uk-section-default uk-section-large uk-padding-remove-bottom uk-margin-xxsmall">
                <div className="uk-container uk-margin-large-bottom uk-flex uk-flex-left">
                    <div className="uk-width-3-5@s">
                        <div className="uk-padding-small uk-margin-large-top">
                            <h1 className="uk-width-1-1 h1__xlarge uk-text-primary">Get in touch.</h1>
                            <p className="uk-text-lead uk-width-1-1">
                                Arrange a demo.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="uk-container uk-container-expand">
                    <div style={{ gap: "2px" }} className="uk-grid uk-grid-collapse uk-child-width-expand@m">
                        <div className="uk-inline uk-overflow-hidden">
                            <Img
                                style={{ height: "100%" }}
                                fluid={data.chicago.childImageSharp.fluid}
                                alt={`Chicago`}
                                objectFit="cover"
                                objectPosition="50% 50%" />
                            <div style={{ backgroundColor: "rgba(24, 30, 70, 0.3)" }} className="uk-position-cover disable-select uk-flex uk-flex-center uk-flex-middle">
                                <h2 className="uk-text-earth-lightest">Chicago</h2>
                            </div>
                        </div>
                        <div className="uk-inline uk-overflow-hidden">
                            <Img
                                style={{ height: "100%" }}
                                fluid={data.kiel.childImageSharp.fluid}
                                alt={`Kiel`}
                                objectFit="cover"
                                objectPosition="50% 50%" />

                            <div style={{ backgroundColor: "rgba(24, 30, 70, 0.3)" }} className="uk-position-cover disable-select uk-flex uk-flex-center uk-flex-middle">
                                <h2 className="uk-text-earth-lightest">Kiel</h2>
                            </div>
                        </div>
                        <div className="uk-inline uk-overflow-hidden">
                            <Img
                                style={{ height: "100%" }}
                                fluid={data.london.childImageSharp.fluid}
                                alt={`London`}
                                objectFit="cover"
                                objectPosition="50% 50%" />

                            <div style={{ backgroundColor: "rgba(24, 30, 70, 0.3)" }} className="uk-position-cover disable-select uk-flex uk-flex-center uk-flex-middle">
                                <h2 className="uk-text-earth-lightest">London</h2>
                            </div>
                        </div>
                        <div className="uk-inline uk-overflow-hidden">
                            <Img
                                style={{ height: "100%" }}
                                fluid={data.toronto.childImageSharp.fluid}
                                alt={`Toronto`}
                                objectFit="cover"
                                objectPosition="50% 50%" />

                            <div style={{ backgroundColor: "rgba(24, 30, 70, 0.3)" }} className="uk-position-cover disable-select uk-flex uk-flex-center uk-flex-middle">
                                <h2 className="uk-text-earth-lightest">Toronto</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="ContactForm" className={`${style.footerContent} footer uk-background-grape`}>
                <div className="uk-container uk-container-expand uk-flex uk-flex-center">
                    <div className="uk-width-5-6 uk-width-3-5@m uk-margin-xlarge-top">
                        <div className="uk-grid uk-grid-large uk-light">
                            <ContactInfo />
                            <div className="uk-width-expand uk-flex-first">
                                <Contact />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-container uk-container-xlarge">
                    <div className="uk-container uk-container-small uk-padding-remove-bottom uk-margin-remove-bottom">
                        <div className="uk-text-center uk-padding uk-padding-remove-horizontal uk-light">
                            <small>&copy; 2018-{new Date().getFullYear()} <a href="https://soleraadvisors.com">Solera Advisors.</a> All Rights Reserved.</small>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    );
}

export default ContactPage;

export const contactQuery = graphql`
    fragment locationImage on File {
        childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    query contactQuery {
        chicago: file(relativePath: { eq: "locations/chicago.jpg" }) {
            ...locationImage
          }
        kiel: file(relativePath: { eq: "locations/kiel.jpg" }) {
            ...locationImage
          }
        london: file(relativePath: { eq: "locations/london.jpg" }) {
            ...locationImage
          }
        toronto: file(relativePath: { eq: "locations/toronto.jpg" }) {
            ...locationImage
        }
    }`
